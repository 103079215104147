html,
body {
  /*height: 100%;*/
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: inherit;
}

img {
  font-size: 0px;
}

#root {
  min-height: 100%;
  position: relative;
}

.header-enter {
  opacity: 0.01;
}

.header-enter.header-enter-active {
  opacity: 1;
  transition: opacity 100ms ease-in;
}

.header-leave {
  opacity: 1;
}

.header-leave.header-leave-active {
  opacity: 0.01;
  transition: opacity 100ms ease-in;
}

@font-face {
  font-family: GintoBlack;
  src: url("fonts/GintoBlack.otf");
  font-display: swap;
}

@font-face {
  font-family: SolarDisplay;
  src: url("fonts/SolarDisplay.woff2");
  font-display: swap;
}

.react-slidedown {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes shimmer {
  0%,
  40% {
    left: -50%;
  }
  60%,
  100% {
    left: 150%;
  }
}

@keyframes pulse-green {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 166, 147, 0.7);
  }

  70% {
    box-shadow: 0 0 0 20px rgba(0, 166, 147, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 166, 147, 0);
  }
}

video::-webkit-media-controls-start-playback-button {
  display: none !important;
}


.splide__arrow {
  border: 1px solid black !important;
  background: none !important;
  transition: all 150ms ease;
  width: 50px !important;
  height: 50px !important;
}

.splide__arrow--next {
  right: 4em !important;
}

.splide__arrow--prev {
  left: 4em !important;
}

.splide__arrow:hover {
  background: #4D4D4D !important;
  fill: white !important;
}

.splide__arrow:hover > svg {
  fill: white !important;
}

.splide__pagination {
  margin-top: 16px;
}